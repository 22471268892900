@use "sass:math";
@use "sass:meta";
@use "vars";

@function pow($number, $exponent) {
	$value: 1;

	@if $exponent > 0 {
		@for $i from 1 through $exponent {
			$value: $value * $number;
		}
	} @else if $exponent < 0 {
		@for $i from 1 through -$exponent {
			$value: math.div($value, $number);
		}
	}

	@return $value;
}

@function rem($target-px, $context: vars.$base-font-size) {
	@return math.div($target-px, $context) * 1rem;
}

@mixin type-size($power) {
	font-size: 1rem * pow(vars.$scale-tiny, $power);

	@media (min-width: vars.$break__type-tiny) {
		font-size: 1rem * pow(vars.$scale-small, $power);
	}

	@media (min-width: vars.$break__type-small) {
		font-size: 1rem * pow(vars.$scale-mid, $power);
	}

	// @media (min-width: $break__type-big) {
	//   font-size: 1rem * math.pow($scale-big, $power);
	// }
}

@mixin valid-quantity($quantity) {
	@if meta.type-of($quantity) != "number" {
		@error 'The "quantity" parameter must be a number!';
	}
	@if not(math.is-unitless($quantity)) {
		@error 'The "quantity" parameter must not have a unit!';
	}
	@if $quantity < 0 {
		@error 'The "quantity" parameter must be at least 0!';
	}
}

@mixin has-nth($expression, $element: "*") {
	&:nth-last-child(#{$expression}):first-child,
	&:nth-last-child(#{$expression}):first-child ~ #{$element} {
		@content;
	}
}

@mixin at-least($quantity, $element: "*") {
	@include valid-quantity($quantity);
	@include has-nth("n + #{$quantity}", $element) {
		@content;
	}
}

@mixin at-most($quantity, $element: "*") {
	@include valid-quantity($quantity);
	@include has-nth("-n + #{$quantity}", $element) {
		@content;
	}
}

@mixin divisible-by($quantity, $offset: 0, $element: "*") {
	@include valid-quantity($quantity);
	@include has-nth("#{$quantity}n + #{$offset}", $element) {
		@content;
	}
}

@mixin has-exactly($quantity, $element: "*") {
	@include valid-quantity($quantity);
	@include has-nth("#{$quantity}", $element) {
		@content;
	}
}

@mixin has-odd($element: "*") {
	@include has-nth("odd", $element) {
		@content;
	}
}

@mixin has-even($element: "*") {
	@include has-nth("even", $element) {
		@content;
	}
}

@mixin skewiff($angle) {
	transform: rotateZ($angle);
	.content {
		transform: rotateZ(#{0 - $angle});
	}
}

@mixin triggered() {
	&:hover,
	&:focus {
		@content;
	}
}
