@use "../00-base/func";
@use "../00-base/vars";

.mainFooter {
  ul {
    list-style: none;
    text-align: center;
    margin: func.rem(36px) 0;
    padding: 0 1em;
  }
  li {
    font-size: func.rem(14px);
    font-weight: 100;
    display: inline-block;
    margin: 0 1em;
  }
  a,
  button {
    text-decoration: none;
    color: vars.$c__black;
    @include func.triggered {
      color: vars.$c__accent;
    }
  }
}
