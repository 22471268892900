@use "sass:math";
@use "../00-base/func";

.page-home main {
	overflow: hidden;
	padding-top: 4em;
	h1 {
		font-size: func.rem(72px);
		margin: 0 auto;
		line-height: math.div(68, 72);
		max-width: 4em;
		width: func.rem(250px);
	}
	.bixeTitle {
		width: func.rem(402px);
		height: func.rem(138px);
	}
	p {
		margin: func.rem(36px) 0;
		font-size: func.rem(18px);
		max-width: 18em;
	}

	.btn {
		width: func.rem(294px);
	}

	aside {
		height: func.rem(526px);
		margin-top: func.rem(74px);
		margin-left: -1em;
		margin-bottom: func.rem(38px);
		margin-right: -1em;
		position: relative;
		min-width: 100%;
		//overflow: hidden;
		img {
			height: func.rem(526px);
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: (0 - math.div(545px, 2) - math.div(59px, 2));
			width: func.rem(545px);
			max-width: none;
		}
	}
	@media only screen and (min-width: 72em) {
		width: func.rem(980px + 48px);
		padding-right: func.rem(638px);
		padding-top: func.rem(128px);
		padding-bottom: func.rem(128px);
		position: relative;
		margin: auto;
		align-items: flex-start;
		h1 {
			margin: 0;
		}
		aside {
			position: absolute;
			right: 1em;
			top: 0;
			bottom: 0;
			margin: 0;
			display: flex;
			min-width: auto;
			overflow: auto;
			min-height: func.rem(599px);
			height: 100%;
			justify-content: center;
			align-items: center;
			img {
				position: relative;
				margin-left: 0;
				width: func.rem(638px);
				height: func.rem(599px);
				left: 0;
			}
		}
	}
}
