@use "../00-base/func";
@use "../00-base/vars";

html {
  font-family: vars.$font__basic;
  font-size: 24px;
  font-weight: 200;
}

strong {
  font-weight: bold;
}

.btn {
  border: 2px solid vars.$c__accent;
  color: vars.$c__accent;
  background: vars.$c__white;
  text-decoration: none;
  font-size: func.rem(14px);
  padding: func.rem(14px);
  text-align: center;
  @include func.triggered {
    background: vars.$c__accent;
    color: vars.$c__white;
  }
}

button.link {
  border: none;
  background: none;
  padding: 0;
  @include func.triggered {
    color: vars.$c__accent;
  }
}

h1,
h2,
h3 {
  font-family: vars.$font__deco;
  font-weight: 400;
  text-transform: uppercase;
}
