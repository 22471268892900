// Colours

$c__blue: #00a2ff;
$c__green: #b2d235;
$c__yellow: #f5d010;
$c__black: #000000;
$c__white: #ffffff;
$c__ghost-white: #f8f8f8;

$c__background: $c__ghost-white;
$c__text: $c__black;
$c__accent: $c__blue;

// fonts
$font__deco: "ProximaNovaCondensed", "Proxima Nova Extra Condensed",
  Lucida Grande, Arial, sans-serif;
$font__basic: "ProximaNovaAlt", "Proxima Nova Alt", Lucida Grande, Arial,
  sans-serif;
$bold: 700;

$base-font-size: 24px;

$scale-tiny: 1.12;
$scale-small: 1.15;
$scale-mid: 1.25;
$scale-big: 1.33;

// Layout vars
$content-width: 45em;
$nav-rotation: 0.5deg;

// break points

$break__type-tiny: 500px;
$break__type-small: 800px;
$break__type-big: 1200px;
$break__sticky-footer: $break__type-small;
