@use "../00-base/func";

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	background: #f8f8f8;
	overflow-x: hidden;
}

.frame {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
}

main {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;
	margin-top: func.rem(48px);
	padding: 1em;
	flex: 1;
}
