@font-face {
  font-family: "ProximaNovaAlt";
  src: url("/font/proximanovaalt-thin-webfont.eot");
  src: url("/font/proximanovaalt-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaalt-thin-webfont.woff") format("woff"),
    url("/font/proximanovaalt-thin-webfont.ttf") format("truetype"),
    url("/font/proximanovaalt-thin-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaAlt";
  src: url("/font/proximanovaalt-light-webfont.eot");
  src: url("/font/proximanovaalt-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaalt-light-webfont.woff") format("woff"),
    url("/font/proximanovaalt-light-webfont.ttf") format("truetype"),
    url("/font/proximanovaalt-light-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaAlt";
  src: url("/font/proximanovaalt-reg-webfont.eot");
  src: url("/font/proximanovaalt-reg-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaalt-reg-webfont.woff") format("woff"),
    url("/font/proximanovaalt-reg-webfont.ttf") format("truetype"),
    url("/font/proximanovaalt-reg-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaAlt";
  src: url("/font/proximanovaalt-sbold-webfont.eot");
  src: url("/font/proximanovaalt-sbold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaalt-sbold-webfont.woff") format("woff"),
    url("/font/proximanovaalt-sbold-webfont.ttf") format("truetype"),
    url("/font/proximanovaalt-sbold-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaAlt";
  src: url("/font/proximanovaalt-xbold-webfont.eot");
  src: url("/font/proximanovaalt-xbold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaalt-xbold-webfont.woff") format("woff"),
    url("/font/proximanovaalt-xbold-webfont.ttf") format("truetype"),
    url("/font/proximanovaalt-xbold-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaCondensed";
  src: url("/font/proximanovaexcn-bold-webfont.eot");
  src: url("/font/proximanovaexcn-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/font/proximanovaexcn-bold-webfont.woff") format("woff"),
    url("/font/proximanovaexcn-bold-webfont.ttf") format("truetype"),
    url("/font/proximanovaexcn-bold-webfont.svg#ProximaNovaRgRegular")
      format("svg");
  font-weight: 500;
  font-style: normal;
}
