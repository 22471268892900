@use "sass:math";
@use "../00-base/func";
@use "../00-base/vars";

header > * {
}

.mainHeader {
	font-weight: 100;
	//padding: rem(26px) rem(44px);
	position: fixed;
	z-index: 2;
}

.bixeLogo {
	padding: 0;
	border: none;
	display: none;
	top: func.rem(23px);
	left: func.rem(43px);
	height: func.rem(50px);
	width: func.rem(50px);

	position: absolute;
	svg {
		color: vars.$c__white;
		.e,
		.w {
			color: vars.$c__text;
		}
		.n,
		.s {
			color: vars.$c__green;
		}
		.nw,
		.se {
			color: vars.$c__yellow;
		}
		.ne,
		.sw {
			color: vars.$c__blue;
		}
	}
	.stroke {
		fill: none;
		stroke: currentColor;
		stroke-width: 8;
	}
	.fill {
		fill: currentColor;
		stroke: currentColor;
		stroke-width: 8;
	}
	@media only screen and (min-width: 40em) {
		display: block;
	}
}

.wordmark {
	height: func.rem(20px);
	width: func.rem(43px);
	color: black;
	top: func.rem(36px);
	left: func.rem(43px);
	display: block;
	position: absolute;
	@media only screen and (min-width: 40em) {
		left: func.rem(104px);
	}
}

.topHeading {
	position: absolute;
	font-family: vars.$font__basic;
	font-size: func.rem(14px);
	font-weight: 300;
	line-height: math.div(17, 14);
	top: func.rem(40px);
	left: func.rem(103px);
}

.sidebar {
	button {
		position: fixed;
		top: 0.5em;
		right: 0.5em;
		z-index: 11;
		background: transparent;
		height: func.rem(56px);
		width: func.rem(56px);
		border: none;
		color: vars.$c__black;
		@include func.triggered {
			color: vars.$c__accent;
			outline: none;
			outline-color: transparent;
			outline-style: none;
		}

		&:focus-visible {
			color: vars.$c__accent;
			outline: none !important;
		}

		.icon {
			position: absolute;
			top: func.rem(18px);
			right: func.rem(24px);
			width: func.rem(32px);
			height: func.rem(32px);
			display: block;
			transform-origin: 50% 50%;
			transition: transform 0.6s ease;
		}
		svg {
			width: 100%;
		}
	}
	.stroke {
		stroke: currentColor;
	}

	button[aria-expanded="true"] .hamburger,
	button[aria-expanded="false"] .cross {
		transform: scale(0);
	}

	.menu {
		z-index: 10;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		background: white;
		width: func.rem(300px);
		padding: func.rem(125px) func.rem(57px) func.rem(57px) func.rem(57px);
		transition: transform 0.6s ease;
		transform: translateX(100%);
	}

	button[aria-expanded="true"] + .menu {
		transform: translateX(0%);
	}

	h2,
	a {
		font-size: func.rem(18px);
	}
	h2 {
		font-family: vars.$font__basic;
		text-transform: none;
	}
	a {
		display: block;
		text-decoration: none;
		color: vars.$c__black;
		@include func.triggered {
			color: vars.$c__accent;
		}
	}
}
